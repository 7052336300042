import create from 'zustand';
import { devtools } from 'zustand/middleware';

type ConfirmPopupStore = {
  title: string;
  isVisible: boolean;
  callback: () => void;
};

type ConfirmPopupStoreWithMethods = ConfirmPopupStore & {
  setConfirmationPopup: (title: string, callback: () => void) => void;
  confirm: () => void;
  cancel: () => void;
};

const initialState: ConfirmPopupStore = {
  title: '',
  isVisible: false,
  callback: () => null,
};

const useConfirmPopupStore = create<ConfirmPopupStoreWithMethods>()(
  devtools((set, get) => ({
    ...initialState,

    setConfirmationPopup(title, callback) {
      set({ title, callback, isVisible: true });
    },

    async confirm() {
      const { callback } = get();
      await callback();
      set(initialState);
    },

    cancel() {
      set(initialState);
    },
  }))
);

export default useConfirmPopupStore;
