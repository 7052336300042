import { useTranslation } from 'hooks';
import './loader.scss';

type LoadingProps = {
  progress?: {
    current: number;
    total: number;
  };
};

const Loading = ({ progress }: LoadingProps) => {
  const { t } = useTranslation();
  const progressPercentage: number | null = progress
    ? Math.ceil((progress.current / progress.total) * 100)
    : null;

  return (
    <div className="fixed backdrop-blur bg-flinkGray/25 w-full h-full top-0 left-0 flex justify-center items-center z-50">
      <div className="bg-flinkGray border-flinkPink-medium border p-10 relative w-1/2 h-1/4 rounded-md">
        <div className="flex flex-col h-full justify-center">
          <div className="flex justify-center items-center">
            <div className="lds-dual-ring"></div>
            <h3 className="text-3xl text-white ml-3">{t('component.loading.heading.title')}</h3>
          </div>
          {progress ? (
            <div data-testid="loading-progress" className="text-white text-2xl text-center">
              {progressPercentage || 0}%
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Loading;
