import { reverse } from 'lodash';
import {
  BulkAddOrReplaceImageMutation,
  BulkUploadFilesType,
  BulkUploadImageMutation,
  FileUpload,
} from 'types';
import { ImagePositionedInput } from 'api/generated';

export const cloudinaryUrl = (url?: string, transform = 'w_500,h_666,c_fit'): string => {
  if (!url) return '';

  const base = `https://res.cloudinary.com/goflink/image/upload/${transform}`;
  const parts = reverse(url.split('/'));
  const folder =
    process.env.ENV === 'production' ? 'product-images-prod' : 'product-images-staging';

  const bucket = parts[1] === 'products' ? parts[2] : parts[1];
  const file = parts[0];

  switch (bucket) {
    case 'flink-media':
      return `${base}/de/products/${file}`;

    case 'flink-media-at':
      return `${base}/at/products/${file}`;

    case 'flink-media-fr':
      return `${base}/fr/products/${file}`;

    case 'flink-media-nl':
      return `${base}/nl/products/${file}`;

    case 'flink-media-test':
      return `${base}/de-test/products/${file}`;

    case 'flink-media-at-test':
      return `${base}/at-test/products/${file}`;

    case 'flink-media-fr-test':
      return `${base}/fr-test/products/${file}`;

    case 'flink-media-nl-test':
      return `${base}/nl-test/products/${file}`;

    default:
      return `${base}/${folder}/${file}`;
  }
};

export const getImageDimensions = (objectUrl: string) => {
  return new Promise<{ w: number; h: number }>((resolve) => {
    const image = new Image();

    image.addEventListener('load', () => {
      resolve({
        w: image.width,
        h: image.height,
      });
    });

    image.src = objectUrl;
  });
};

export const filterImages = (images: FileUpload[]): { valid: FileUpload[]; invalid: string[] } => {
  const valid = images.filter((i) => imageFileValidator(i.name));

  const invalid = images.reduce((prev: string[], currentImage) => {
    if (!imageFileValidator(currentImage.name)) {
      prev.push(currentImage.name);
    }

    return prev;
  }, []);

  return {
    valid,
    invalid,
  };
};

export const imageFileValidator = (name: string): boolean => {
  const expression = new RegExp('^([0-9]{8,9})(_)([0-9]{1,2})(.png)$');
  return expression.test(name);
};

export const getSkuFromImage = (name: string): string => {
  const nameArr: string[] = name.split(/_[0-9]{1,2}.png/).filter(Boolean);
  return nameArr.pop() as string;
};

export const getPositionFromImage = (name: string): number => {
  const nameArr: string[] = name.split(/_|.png/).filter(Boolean);
  return Number(nameArr.pop()) - 1;
};

export const groupImagesBySku = (files: FileUpload[]): BulkUploadFilesType => {
  return files.reduce((prev: BulkUploadFilesType, file) => {
    const sku = getSkuFromImage(file.name);

    if (sku) {
      if (!prev[sku]) {
        prev[sku] = [];
      }

      prev[sku].push(file);
    }

    return prev;
  }, {});
};

export const callBulkUpload = async (
  files: FileUpload[],
  uploadImageMutation: BulkUploadImageMutation,
  addOrReplaceImageMutation: BulkAddOrReplaceImageMutation
): Promise<void> => {
  const groupedImages = groupImagesBySku(files);

  for await (const [sku, images] of Object.entries(groupedImages)) {
    const imagesWithUrl: Array<ImagePositionedInput> = await Promise.all(
      images.map(async (image) => {
        const url = await uploadImageMutation(image);
        const position = getPositionFromImage(image.name);

        return {
          position,
          image: {
            url,
            label: '',
            h: image?.h || 0,
            w: image?.w || 0,
          },
        };
      })
    );

    await addOrReplaceImageMutation(sku, imagesWithUrl);
  }
};
