import { memo } from 'react';

type SeparatorProps = {
  additionalClass?: string;
};

const Separator = ({ additionalClass }: SeparatorProps) => (
  <div className={`border-2 border-flinkPink-medium ${additionalClass}`} />
);

export default memo(Separator);
