import { useTranslation } from 'hooks';
import { ToastContainer } from 'react-toastify';
import { Link, useMatch, useNavigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'lib/constants';

import Modal from 'components/Modal';
import Button from 'components/Button';
import ConfirmPopup from 'components/ConfirmPopup';

const Layout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isIndexPage = useMatch(ROUTES.PRODUCT);

  return (
    <div id="product-module">
      <div className="h-full min-h-screen">
        <div className="border-flinkGray-lighter border-b border-solid px-10">
          <div className="h-20 flex justify-between items-center">
            <Link to={ROUTES.PRODUCT}>
              <h1 className="text-2xl text-white font-bold">{t('page.layout.heading.title')}</h1>
            </Link>

            {isIndexPage && (
              <Button
                text={t('page.layout.button.bulk')}
                type="button"
                icon="bulk_uploads"
                onClick={() => navigate(ROUTES.PRODUCT_BULK_UPLOAD)}
              />
            )}
          </div>
        </div>

        <div className="relative my-4 px-10">
          <Outlet />
        </div>

        {!isIndexPage && (
          <Link className="text-flinkPink inline-block px-10 mb-4" to={ROUTES.PRODUCT}>
            {t('page.notfound.link.back')}
          </Link>
        )}
      </div>
      <Modal />
      <ConfirmPopup />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        newestOnTop={true}
        closeOnClick
        theme="colored"
        hideProgressBar
      />
    </div>
  );
};

export default Layout;
