import client from 'lib/client';
import {
  AddProductImageDocument,
  RemoveProductImageDocument,
  GetProductDocument,
  UploadImageDocument,
  ReplaceProductImageMutation,
  GetProductQuery,
  UploadImageMutation,
  AddProductImageMutation,
  ReplaceProductImageDocument,
  RemoveProductImageMutation,
  UploadImageMutationVariables,
  GetProductQueryVariables,
  AddProductImageMutationVariables,
  ReplaceProductImageMutationVariables,
  RemoveProductImageMutationVariables,
  MoveImageMutationVariables,
  MoveImageMutation,
  MoveImageDocument,
  AddOrReplaceImagesMutationVariables,
  AddOrReplaceImagesMutation,
  AddOrReplaceImagesDocument,
} from './generated';

export const replaceImage = (variables: ReplaceProductImageMutationVariables) => {
  return client
    .mutation<ReplaceProductImageMutation, ReplaceProductImageMutationVariables>(
      ReplaceProductImageDocument,
      variables
    )
    .toPromise();
};

export const removeImage = (variables: RemoveProductImageMutationVariables) => {
  return client
    .mutation<RemoveProductImageMutation, RemoveProductImageMutationVariables>(
      RemoveProductImageDocument,
      variables
    )
    .toPromise();
};

export const addImage = (variables: AddProductImageMutationVariables) => {
  return client
    .mutation<AddProductImageMutation, AddProductImageMutationVariables>(
      AddProductImageDocument,
      variables
    )
    .toPromise();
};

export const uploadImage = (variables: UploadImageMutationVariables) => {
  return client
    .mutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, variables)
    .toPromise();
};

export const moveImages = (variables: MoveImageMutationVariables) => {
  return client
    .mutation<MoveImageMutation, MoveImageMutationVariables>(MoveImageDocument, variables)
    .toPromise();
};

export const addOrReplaceImages = (variables: AddOrReplaceImagesMutationVariables) => {
  return client
    .mutation<AddOrReplaceImagesMutation, AddOrReplaceImagesMutationVariables>(
      AddOrReplaceImagesDocument,
      variables
    )
    .toPromise();
};

export const getProduct = (variables: GetProductQueryVariables) => {
  return client
    .query<GetProductQuery, GetProductQueryVariables>(GetProductDocument, variables)
    .toPromise();
};
