import { memo } from 'react';
import Icon from 'components/Icon';

type ButtonProps = {
  text: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  background?: string;
  additionalClass?: string;
  isDisabled?: boolean;
  icon?: string;
};

const Button = ({
  text,
  type = 'button',
  onClick,
  background = 'bg-flinkPink',
  additionalClass,
  isDisabled = false,
  icon,
}: ButtonProps) => (
  <button
    type={type}
    className={`${
      icon ? 'flex gap-1' : ''
    } items-center py-2 px-3 cursor-pointer text-white rounded ${background} ${additionalClass} disabled:opacity-50`}
    onClick={onClick}
    disabled={isDisabled}
  >
    {text} {icon ? <Icon icon={icon} size="small" /> : null}
  </button>
);

export default memo(Button);
