import type { Location } from 'react-router-dom';

const getSkuFromLocation = (location: Location): string => {
  const { pathname } = location;
  const pathArr: string[] = pathname?.split('/').filter(Boolean) || [];

  return pathArr[1];
};

export default getSkuFromLocation;
