import { useRef, useState } from 'react';
import { useTranslation } from 'hooks';

import { useOnClickOutside } from '@flpkg/hooks';

import { Image } from 'api/generated';
import useModalStore from 'store/useModal';
import useProductStore from 'store/useProduct';
import useConfirmPopup from 'store/useConfirmPopup';
import { cloudinaryUrl } from 'lib/imageUtils';

import Icon from 'components/Icon';
import MenuButton from './MenuButton';

import { ModalType } from 'types';

type MenuToggleProps = {
  position: number;
  image: Image;
};

const MenuToggle = ({ position, image }: MenuToggleProps) => {
  const { t } = useTranslation();
  const { setModalType, setEditSelectedImage, setIsLoading, resetModal } = useModalStore();
  const { deleteImage } = useProductStore();
  const { setConfirmationPopup } = useConfirmPopup();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef(null);
  const openBtnRef = useRef(null);

  useOnClickOutside(menuRef, openBtnRef, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  const previewBtnHandler = () => {
    window.open(cloudinaryUrl(image.url), '_blank');
    setShowMenu(false);
  };

  const replaceBtnHandler = () => {
    setModalType(ModalType.REPLACE);
    setEditSelectedImage(image, position);
    setShowMenu(false);
  };

  const deleteBtnHandler = () => {
    setConfirmationPopup(t('component.modal.confirm.delete'), async () => {
      setIsLoading(true);
      await deleteImage(image);
      resetModal();
    });
  };

  return (
    <div className="relative">
      <button className="cursor-pointer" ref={openBtnRef} onClick={() => setShowMenu(!showMenu)}>
        <Icon icon="menu_dots" />
      </button>
      <div
        ref={menuRef}
        hidden={!showMenu}
        className="absolute top-7 -right-1 bg-white rounded min-w-[6rem] shadow-md z-10"
        data-testid="menutoggle-list"
      >
        <MenuButton
          text={t('component.menutoggle.button.preview')}
          onClickHandler={previewBtnHandler}
        />
        <MenuButton
          text={t('component.menutoggle.button.replace')}
          onClickHandler={replaceBtnHandler}
        />
        <div className="h-0.5 border-dashed border-t border-flinkGray-light" />
        <MenuButton
          text={t('component.menutoggle.button.remove')}
          onClickHandler={deleteBtnHandler}
        />
      </div>
    </div>
  );
};

export default MenuToggle;
