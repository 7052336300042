import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ProductStore, ProductStoreWithMethods } from './types';
import { CombinedError } from 'urql';

import {
  getProduct,
  uploadImage,
  addImage,
  replaceImage,
  removeImage,
  moveImages,
} from 'api/product';

const initialState: ProductStore = {
  id: '',
  sku: '',
  images: [],
  error: undefined,
};

const useProductStore = create<ProductStoreWithMethods>()(
  devtools((set, get) => ({
    ...initialState,
    resetProduct() {
      set(initialState);
    },

    async setDataFromQuery(sku) {
      const { setError } = get();
      const { data, error } = await getProduct({ sku });

      if (error) {
        setError(error);
        return null;
      }

      set((currentState) => ({ ...currentState, ...data?.product }));
    },

    setSku(sku: string) {
      set((currentState) => ({ ...currentState, sku }));
    },

    async uploadImage(file) {
      const { setError } = get();
      const { data, error } = await uploadImage({ file });

      if (error) {
        setError(error);
        return '';
      }

      return data?.uploadImage.url || '';
    },

    async addImage(file) {
      const { sku, uploadImage, setError } = get();
      const url = await uploadImage(file);

      if (!url.trim()) {
        return setError(new CombinedError({ networkError: new Error('Error uploading image') }));
      }

      const { data, error } = await addImage({
        data: {
          sku,
          image: {
            url,
            h: file?.h || 0,
            w: file?.w || 0,
            label: '',
          },
        },
      });

      if (error) {
        return setError(error);
      }

      return set((currentState) => ({
        ...currentState,
        ...data?.addProductImage,
      }));
    },

    async replaceImage(file, position) {
      const { sku, uploadImage, setError } = get();

      const url = await uploadImage(file);

      if (!url.trim()) {
        return setError(new CombinedError({ networkError: new Error('Error uploading image') }));
      }

      const { data, error } = await replaceImage({
        data: {
          sku,
          position,
          image: {
            h: file?.h || 0,
            w: file?.w || 0,
            label: '',
            url,
          },
        },
      });

      if (error) {
        return setError(error);
      }

      return set((currentState) => ({ ...currentState, ...data?.replaceProductImage }));
    },

    async deleteImage(image) {
      const { sku, setError } = get();

      const { data, error } = await removeImage({
        data: {
          sku,
          image: {
            label: image.label,
            url: image.url,
            h: image.dimensions.h,
            w: image.dimensions.w,
          },
        },
      });
      if (error) {
        return setError(error);
      }

      return set((currentState) => ({ ...currentState, ...data?.removeProductImage }));
    },

    async moveImages(images) {
      const { sku, setError } = get();

      const { data, error } = await moveImages({
        data: {
          sku,
          images,
        },
      });

      if (error) {
        return setError(error);
      }

      return set((currentState) => ({ ...currentState, ...data?.moveProductImages }));
    },

    setError(error) {
      set((currentState) => ({ ...currentState, error }));
    },
  }))
);

export default useProductStore;
