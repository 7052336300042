import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'hooks';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import useModalStore from 'store/useModal';
import useProductStore from 'store/useProduct';
import { cloudinaryUrl } from 'lib/imageUtils';
import { ImageInput } from 'api/generated';

import Title from 'components/Typography/Title';
import Button from 'components/Button';
import Separator from 'components/Separator';

const reorder = (list: ImageInput[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  background: isDragging ? '#E31C79' : 'transparent',
  padding: '0.25rem',
  ...draggableStyle,
});

const MoveModal = () => {
  const { t } = useTranslation();
  const [reorderedImages, setReorderedImages] = useState<ImageInput[]>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const { images, moveImages } = useProductStore();
  const { setIsLoading, resetModal } = useModalStore();

  const remapImages = useCallback(() => {
    const mappedImages = images.map(
      (image): ImageInput => ({
        label: image?.label || '',
        url: image?.url || '',
        h: image?.dimensions.h || 0,
        w: image?.dimensions.w || 0,
      })
    );

    setReorderedImages(mappedImages);
  }, [images]);

  useEffect(() => {
    if (images) {
      remapImages();
    }
  }, [images, remapImages]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(reorderedImages, result.source.index, result.destination.index);
    setReorderedImages(items);
    setIsEdited(true);
  };

  const saveReorder = async () => {
    setIsLoading(true);
    await moveImages(reorderedImages);
    resetModal();
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Title text={t('component.modal.heading.reorder')} />
        <Separator additionalClass="mb-10" />

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="product-move" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={`inline-flex p-2 overflow-auto gap-2 border border-dashed border-flinkPink ${
                  snapshot.isDraggingOver ? 'bg-flinkPink-light' : ''
                }`}
                {...provided.droppableProps}
              >
                {reorderedImages.map((image, index) => (
                  <Draggable key={image?.url} draggableId={image?.url as string} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                        }}
                      >
                        <img
                          src={cloudinaryUrl(image?.url, 'h_200,w_200,c_fit')}
                          alt="product-img"
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="mt-10 flex justify-between">
        <div className="flex gap-3">
          <Button text={t('component.modal.button.reset')} onClick={remapImages} icon="refresh" />
          <Button
            background={'bg-red'}
            text={t('component.modal.button.close')}
            onClick={resetModal}
            icon="close"
          />
        </div>
        <div>
          <Button
            isDisabled={!isEdited}
            background={'bg-flinkGreen'}
            text={t('component.modal.button.save')}
            onClick={saveReorder}
            icon="check"
          />
        </div>
      </div>
    </div>
  );
};

export default MoveModal;
