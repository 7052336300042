import { Fragment, useEffect } from 'react';
import { useTranslation } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'lib/constants';
import { cloudinaryUrl } from 'lib/imageUtils';
import getSkuFromLocation from 'lib/getSkuFromLocation';
import useModalStore from 'store/useModal';
import useProductStore from 'store/useProduct';

import Item from 'components/Image/Item';
import Title from 'components/Typography/Title';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Separator from 'components/Separator';
import MenuToggle from 'components/MenuToggle';

import { ModalType } from 'types';

const ProductEditPage = () => {
  const { t } = useTranslation();
  const { isLoading, setIsLoading, setModalType } = useModalStore();
  const { id, sku, images, error, setDataFromQuery } = useProductStore();
  const location = useLocation();
  const navigate = useNavigate();
  const skuFromLocation = getSkuFromLocation(location);

  useEffect(() => {
    const queryForProduct = async (sku: string) => {
      setIsLoading(true);
      await setDataFromQuery(sku);
      setIsLoading(false);
    };

    if (skuFromLocation) {
      queryForProduct(skuFromLocation);
    } else {
      navigate(ROUTES.PRODUCT);
    }
  }, [navigate, setDataFromQuery, setIsLoading, skuFromLocation]);

  useEffect(() => {
    if (error) {
      navigate(ROUTES.PRODUCT_NOT_FOUND);
    }
  }, [error, navigate]);

  const imagesAvailable = images.length;

  return (
    <div className="bg-flinkGray px-4 py-8 rounded-lg">
      {isLoading && <Loading />}
      {id ? (
        <Fragment>
          <Title text={`SKU - ${sku}`} />

          <Separator additionalClass="mb-10" />

          <div
            className={`gap-5 my-2 grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 ${
              imagesAvailable ? 'grid' : ''
            }`}
          >
            {imagesAvailable ? (
              images.map((image, index) => (
                <Item key={image?.label || index}>
                  <div>
                    <img
                      className="w-full"
                      src={cloudinaryUrl(image?.url, 'h_200,w_200,c_fit')}
                      alt={image?.label}
                    />
                  </div>
                  {image && <MenuToggle position={index} image={image} />}
                </Item>
              ))
            ) : (
              <h3 className="text-lg text-white mb-4">{t('page.edit.images.notfound')}</h3>
            )}
          </div>

          <div className="flex gap-3 mt-10">
            <Button
              text={t('page.edit.button.add')}
              onClick={() => setModalType(ModalType.ADD)}
              icon="plus"
            />
            {!!imagesAvailable && (
              <Button
                text={t('page.edit.button.reorder')}
                onClick={() => setModalType(ModalType.REORDER)}
                icon="move"
              />
            )}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default ProductEditPage;
