import { useEffect } from 'react';
import GenericModal from './GenericModal';
import MoveModal from './MoveModal';
import Icon from 'components/Icon';
import useModalStore from 'store/useModal';
import { ModalType } from 'types';

const loadChildModal = (type: number | null) => {
  switch (type) {
    case ModalType.ADD:
    case ModalType.REPLACE:
      return <GenericModal />;
    case ModalType.REORDER:
      return <MoveModal />;
    default:
      return null;
  }
};

const Modal = () => {
  const { resetModal, type } = useModalStore();

  useEffect(() => {
    const closeAfterEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        resetModal();
      }
    };

    document.addEventListener('keydown', closeAfterEscapeKey);
    return () => {
      document.removeEventListener('keydown', closeAfterEscapeKey);
    };
  }, [resetModal]);

  if (!type) {
    return null;
  }

  return (
    <div className="fixed backdrop-blur bg-flinkGray/25 w-full h-full top-0 left-0 flex justify-center items-center z-30">
      <div className="bg-flinkGray border-flinkPink-medium border p-10 relative w-3/4 rounded-md">
        <div className="absolute top-5 right-5">
          <button onClick={resetModal}>
            <Icon icon="close" size="large" withBackground />
          </button>
        </div>

        {loadChildModal(type)}
      </div>
    </div>
  );
};

export default Modal;
