import { useEffect } from 'react';
import { useTranslation } from 'hooks';

import Title from 'components/Typography/Title';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Loading from 'components/Loading';
import DragDrop from 'components/DragDrop';
import Separator from 'components/Separator';
import Thumbnails from 'components/Image/Thumbnails';

import useModalStore from 'store/useModal';
import useBulkUploadStore from 'store/useBulkUpload';
import { callBulkUpload } from 'lib/imageUtils';
import { FileUpload } from 'types';

const BulkUploadPage = () => {
  const { t } = useTranslation();
  const {
    files,
    invalidList,
    uploadCompleted,
    invalidSkusPostUpload,
    successfulSkusUploaded,
    successfulFilesUploaded,
    filesProcessedDuringUpload,
    uploadImage,
    resetBulkUpload,
    setFilesPostDrop,
    addOrReplaceImage,
    promptToastShowCompletedUpload,
  } = useBulkUploadStore();

  const { isLoading, setIsLoading, resetModal } = useModalStore();

  useEffect(() => {
    return () => files.forEach((file) => (file?.preview ? URL.revokeObjectURL(file.preview) : ''));
  }, [files]);

  useEffect(() => {
    return () => resetBulkUpload();
  }, [resetBulkUpload]);

  const onDropHandler = (files: FileUpload[]) => {
    resetBulkUpload();
    setFilesPostDrop(files);
  };

  const beginBulkUpload = async () => {
    setIsLoading(true);
    await callBulkUpload(files, uploadImage, addOrReplaceImage);
    promptToastShowCompletedUpload();
    resetBulkUpload([
      'uploadCompleted',
      'invalidSkusPostUpload',
      'successfulSkusUploaded',
      'successfulFilesUploaded',
    ]);
    resetModal();
  };

  return (
    <div className="bg-flinkGray px-4 py-8 rounded-lg">
      {isLoading ? (
        <Loading progress={{ current: filesProcessedDuringUpload, total: files.length }} />
      ) : null}
      <Title text={t('page.bulk.heading.title')} />

      <Separator additionalClass="mb-10" />

      <DragDrop multiple onDropCallback={onDropHandler} />

      {invalidList.length ? (
        <div className="mt-10">
          <Dialog message={t('page.bulk.error.invalidlist')} list={invalidList} />
        </div>
      ) : null}

      {uploadCompleted ? (
        <>
          <div className="mt-10">
            <Dialog
              message={t('page.bulk.toast.success', {
                successfulFilesUploaded,
                successfulSkusUploaded,
              })}
              status="success"
            />
          </div>
          {invalidSkusPostUpload.length ? (
            <div className="mt-10">
              <Dialog message={t('page.bulk.error.invalidskus')} list={invalidSkusPostUpload} />
            </div>
          ) : null}
        </>
      ) : null}
      {files.length ? (
        <div className="mt-10">
          <Separator />
          <h4 className="text-white text-xl my-5">{t('page.bulk.heading.preview')}</h4>
          <Thumbnails images={files} />

          <div className="flex justify-between mt-5 gap-5">
            <Button
              background={'bg-red'}
              text={t('page.bulk.button.cancel')}
              icon="close"
              onClick={resetBulkUpload}
              isDisabled={!files.length}
            />
            <Button
              background={'bg-flinkGreen'}
              text={t('page.bulk.button.proceed')}
              icon="bulk_uploads"
              onClick={beginBulkUpload}
              isDisabled={!files.length}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BulkUploadPage;
