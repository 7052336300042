import { ChangeEvent, SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'hooks';

import useProductStore from 'store/useProduct';
import { ROUTES } from 'lib/constants';

import Input from 'components/Input';
import Button from 'components/Button';
import BylineError from 'components/Error/BylineError';

const ProductSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sku, error, setSku, resetProduct } = useProductStore();

  useEffect(() => {
    resetProduct();
  }, [resetProduct]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSku(e.currentTarget.value);
  };

  const onSubmitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate(`${ROUTES.PRODUCT}/${sku}`);
  };

  return (
    <div className="flex gap-5 items-end justify-between w-full lg:w-8/12">
      <div className="flex-1">
        <form action="#" onSubmit={onSubmitHandler}>
          <Input
            onChange={onChangeHandler}
            placeholder={t('page.index.input.placeholder') || ''}
            icon="search"
            byline={t('page.index.input.byline') || ''}
            inputMode="numeric"
            patten="[0-9]{8,9}"
            isRequired={true}
          />

          {error && <BylineError error={error} additionalClass={'mt-5'} />}

          <Button
            text={t('page.index.button.search')}
            type="submit"
            additionalClass="mt-5"
            icon="search"
          />
        </form>
      </div>
    </div>
  );
};

export default ProductSearch;
