import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'urql';
import client from 'lib/client';

import ProductSearch from 'pages';
import NotFound from 'pages/NotFound';
import BulkUpload from 'pages/BulkUpload';
import ProductEdit from 'pages/ProductEdit';

import Layout from 'components/Layout';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

function Controller() {
  return (
    <Provider value={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="product" element={<ProductSearch />} />
            <Route path="product/:sku" element={<ProductEdit />} />
            <Route path="product/bulk-upload" element={<BulkUpload />} />
            <Route path="product/404" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default function Root() {
  return <Controller />;
}
