import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  File: any;
};

export type AddOrReplaceImagesInput = {
  images: Array<ImagePositionedInput>;
  sku: Scalars['String'];
};

export type AddProductImageInput = {
  image: ImageInput;
  sku: Scalars['String'];
};

export type Dimension = {
  __typename?: 'Dimension';
  h: Scalars['Int'];
  w: Scalars['Int'];
};

export type DownloadProductCategories = {
  __typename?: 'DownloadProductCategories';
  csv: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  dimensions: Dimension;
  label: Scalars['String'];
  url: Scalars['String'];
};

export type ImageInput = {
  h: Scalars['Int'];
  label: Scalars['String'];
  url: Scalars['String'];
  w: Scalars['Int'];
};

export type ImagePositionedInput = {
  image: ImageInput;
  position: Scalars['Int'];
};

export type Media = {
  __typename?: 'Media';
  url: Scalars['String'];
};

export type MoveProductImagesInput = {
  images: Array<ImageInput>;
  sku: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrReplaceImages: Product;
  addProductImage: Product;
  deleteImage: Media;
  moveProductImages: Product;
  removeProductImage: Product;
  replaceProductImage: Product;
  uploadImage: Media;
  uploadProductCategoryMappingFile: UploadProductDiscountsResponse;
};

export type MutationAddOrReplaceImagesArgs = {
  data: AddOrReplaceImagesInput;
};

export type MutationAddProductImageArgs = {
  data: AddProductImageInput;
};

export type MutationDeleteImageArgs = {
  path: Scalars['String'];
};

export type MutationMoveProductImagesArgs = {
  data: MoveProductImagesInput;
};

export type MutationRemoveProductImageArgs = {
  data: RemoveProductImageInput;
};

export type MutationReplaceProductImageArgs = {
  data: ReplaceProductImageInput;
};

export type MutationUploadImageArgs = {
  file: Scalars['File'];
};

export type MutationUploadProductCategoryMappingFileArgs = {
  file: Scalars['File'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  image?: Maybe<Image>;
  images: Array<Maybe<Image>>;
  sku: Scalars['String'];
};

export type ProductImageArgs = {
  index: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  downloadProductCategories: DownloadProductCategories;
  product: Product;
};

export type QueryProductArgs = {
  sku: Scalars['String'];
};

export type RemoveProductImageInput = {
  image: ImageInput;
  sku: Scalars['String'];
};

export type ReplaceProductImageInput = {
  image: ImageInput;
  position: Scalars['Int'];
  sku: Scalars['String'];
};

export type UploadProductDiscountsResponse = {
  __typename?: 'UploadProductDiscountsResponse';
  message?: Maybe<Scalars['String']>;
};

export type AddOrReplaceImagesMutationVariables = Exact<{
  data: AddOrReplaceImagesInput;
}>;

export type AddOrReplaceImagesMutation = {
  __typename?: 'Mutation';
  addOrReplaceImages: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export type AddProductImageMutationVariables = Exact<{
  data: AddProductImageInput;
}>;

export type AddProductImageMutation = {
  __typename?: 'Mutation';
  addProductImage: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export type UploadProductCategoryMappingFileMutationVariables = Exact<{
  file: Scalars['File'];
}>;

export type UploadProductCategoryMappingFileMutation = {
  __typename?: 'Mutation';
  uploadProductCategoryMappingFile: {
    __typename?: 'UploadProductDiscountsResponse';
    message?: string | null;
  };
};

export type MoveImageMutationVariables = Exact<{
  data: MoveProductImagesInput;
}>;

export type MoveImageMutation = {
  __typename?: 'Mutation';
  moveProductImages: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export type RemoveProductImageMutationVariables = Exact<{
  data: RemoveProductImageInput;
}>;

export type RemoveProductImageMutation = {
  __typename?: 'Mutation';
  removeProductImage: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export type ReplaceProductImageMutationVariables = Exact<{
  data: ReplaceProductImageInput;
}>;

export type ReplaceProductImageMutation = {
  __typename?: 'Mutation';
  replaceProductImage: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export type UploadImageMutationVariables = Exact<{
  file: Scalars['File'];
}>;

export type UploadImageMutation = {
  __typename?: 'Mutation';
  uploadImage: { __typename?: 'Media'; url: string };
};

export type DownloadProductCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type DownloadProductCategoriesQuery = {
  __typename?: 'Query';
  downloadProductCategories: { __typename?: 'DownloadProductCategories'; csv: string };
};

export type GetProductQueryVariables = Exact<{
  sku: Scalars['String'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: string;
    sku: string;
    images: Array<{
      __typename?: 'Image';
      label: string;
      url: string;
      dimensions: { __typename?: 'Dimension'; h: number; w: number };
    } | null>;
  };
};

export const AddOrReplaceImagesDocument = gql`
  mutation AddOrReplaceImages($data: AddOrReplaceImagesInput!) {
    addOrReplaceImages(data: $data) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useAddOrReplaceImagesMutation() {
  return Urql.useMutation<AddOrReplaceImagesMutation, AddOrReplaceImagesMutationVariables>(
    AddOrReplaceImagesDocument
  );
}
export const AddProductImageDocument = gql`
  mutation AddProductImage($data: AddProductImageInput!) {
    addProductImage(data: $data) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useAddProductImageMutation() {
  return Urql.useMutation<AddProductImageMutation, AddProductImageMutationVariables>(
    AddProductImageDocument
  );
}
export const UploadProductCategoryMappingFileDocument = gql`
  mutation uploadProductCategoryMappingFile($file: File!) {
    uploadProductCategoryMappingFile(file: $file) {
      message
    }
  }
`;

export function useUploadProductCategoryMappingFileMutation() {
  return Urql.useMutation<
    UploadProductCategoryMappingFileMutation,
    UploadProductCategoryMappingFileMutationVariables
  >(UploadProductCategoryMappingFileDocument);
}
export const MoveImageDocument = gql`
  mutation MoveImage($data: MoveProductImagesInput!) {
    moveProductImages(data: $data) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useMoveImageMutation() {
  return Urql.useMutation<MoveImageMutation, MoveImageMutationVariables>(MoveImageDocument);
}
export const RemoveProductImageDocument = gql`
  mutation RemoveProductImage($data: RemoveProductImageInput!) {
    removeProductImage(data: $data) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useRemoveProductImageMutation() {
  return Urql.useMutation<RemoveProductImageMutation, RemoveProductImageMutationVariables>(
    RemoveProductImageDocument
  );
}
export const ReplaceProductImageDocument = gql`
  mutation ReplaceProductImage($data: ReplaceProductImageInput!) {
    replaceProductImage(data: $data) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useReplaceProductImageMutation() {
  return Urql.useMutation<ReplaceProductImageMutation, ReplaceProductImageMutationVariables>(
    ReplaceProductImageDocument
  );
}
export const UploadImageDocument = gql`
  mutation UploadImage($file: File!) {
    uploadImage(file: $file) {
      url
    }
  }
`;

export function useUploadImageMutation() {
  return Urql.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument);
}
export const DownloadProductCategoriesDocument = gql`
  query DownloadProductCategories {
    downloadProductCategories {
      csv
    }
  }
`;

export function useDownloadProductCategoriesQuery(
  options?: Omit<Urql.UseQueryArgs<DownloadProductCategoriesQueryVariables>, 'query'>
) {
  return Urql.useQuery<DownloadProductCategoriesQuery, DownloadProductCategoriesQueryVariables>({
    query: DownloadProductCategoriesDocument,
    ...options,
  });
}
export const GetProductDocument = gql`
  query GetProduct($sku: String!) {
    product(sku: $sku) {
      id
      sku
      images {
        label
        url
        dimensions {
          h
          w
        }
      }
    }
  }
`;

export function useGetProductQuery(
  options: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({
    query: GetProductDocument,
    ...options,
  });
}
