import { memo } from 'react';

type DialogProps = {
  message: string;
  list?: Array<string>;
  status?: 'success' | 'error';
};

const Dialog = ({ message, list, status = 'error' }: DialogProps) => {
  const bgColour = status === 'error' ? 'bg-red/50' : 'bg-flinkGreen';

  return (
    <div
      data-testid="dialog"
      className={`p-5 rounded text-white inline-block ${bgColour} min-w-full`}
    >
      <h3 className="text-xl">{message}</h3>
      {list?.length ? (
        <ul className="list-disc mt-3">
          {list.map((name: string, index: number) => (
            <li className="list-inside" key={index}>
              {name}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default memo(Dialog);
