import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ModalStore, ModalStoreWithMethods } from './types';

const initialState: ModalStore = {
  type: null,
  editSelectedImage: undefined,
  isLoading: false,
};

const useModalStore = create<ModalStoreWithMethods>()(
  devtools((set) => ({
    ...initialState,
    resetModal() {
      set(initialState);
    },
    setModalType(type) {
      set((currentState) => ({ ...currentState, isLoading: false, type: type }));
    },
    setEditSelectedImage(image, index) {
      set((currentState) => ({ ...currentState, editSelectedImage: { image, index } }));
    },
    setIsLoading(isLoading) {
      set((currentState) => ({ ...currentState, isLoading, type: null }));
    },
  }))
);

export default useModalStore;
