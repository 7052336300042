import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'hooks';

import { getImageDimensions } from 'lib/imageUtils';

import Icon from 'components/Icon';

import { FileType, FileUpload } from 'types';

type DragAndDropProps = {
  onDropCallback: (T: FileUpload[]) => void;
  multiple?: boolean;
};

const DragAndDrop = ({ onDropCallback, multiple = false }: DragAndDropProps) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      [FileType.PNG]: [],
    },
    maxSize: 10485760, // 10MB
    multiple,
    onDrop: async (acceptedFiles: File[]) => {
      const files = await Promise.all<FileUpload>(
        acceptedFiles.map(async (file: File): Promise<FileUpload> => {
          if (file.type === FileType.PNG) {
            const objectURL = URL.createObjectURL(file);
            const dimension = await getImageDimensions(objectURL);
            return Object.assign(file, { preview: objectURL, ...dimension });
          }

          return file;
        })
      );

      onDropCallback(files);
    },
  });

  const textStatus = useMemo(() => {
    if (isDragReject) return 'text-red';

    if (isDragActive) return 'text-flinkPink';

    return 'text-white';
  }, [isDragReject, isDragActive]);

  const dropStatus = useMemo(() => {
    if (isDragReject) return 'bg-red-light border-red';

    if (isDragActive) return 'bg-flinkPink-light border-flinkPink';

    return 'border-flinkPink-medium';
  }, [isDragReject, isDragActive]);

  const presentationText = (
    <span>
      Drag {multiple ? 'images' : 'image'} here or{' '}
      <span className="border-b border-b-flinkPink">browse file</span>
    </span>
  );
  const rejectedText = t('page.bulk.dragdrop.rejected');

  return (
    <section
      className={`flex-grow max-w-lg border-2 border-dashed rounded-lg py-10 min-h-fit cursor-pointer ${dropStatus}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center">
        <Icon icon="image" size="vlarge" color={isDragActive ? '#E31C79' : 'white'} />
        <h3 className={`text-xl my-3 ${textStatus}`}>
          {isDragReject ? rejectedText : presentationText}
        </h3>
      </div>
    </section>
  );
};

export default memo(DragAndDrop);
