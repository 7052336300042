import { ChangeEventHandler, memo } from 'react';
import Icon from 'components/Icon';

export type InputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  byline?: string;
  icon?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  patten?: string;
  isRequired?: boolean;
};

const Input = ({
  onChange,
  placeholder = '',
  icon,
  byline,
  inputMode = 'text',
  patten = undefined,
  isRequired,
}: InputProps) => {
  return (
    <div>
      <label className="flex bg-flinkGray h-12 justify-between items-center text-lg rounded-lg font-sans">
        <input
          className="px-4 placeholder:text-flinkGray-light text-white bg-flinkGray focus:outline-0"
          type="text"
          onChange={onChange}
          placeholder={placeholder}
          inputMode={inputMode}
          pattern={patten}
          {...(isRequired ? { required: true } : null)}
        />
        {icon ? <Icon className="mr-4" icon={icon} /> : null}
      </label>
      {byline ? <span className="block text-sm pl-2 mt-2 text-white">{byline}</span> : null}
    </div>
  );
};

export default memo(Input);
