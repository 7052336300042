export const LOCALES = {
  EN: 'en',
  DE: 'de',
  FR: 'fr',
  NL: 'nl',
};

export const ROUTES = {
  PRODUCT: '/product',
  PRODUCT_BULK_UPLOAD: '/product/bulk-upload',
  PRODUCT_NOT_FOUND: '/product/404',
};
