import { useEffect } from 'react';
// SOURCE
// https://usehooks.com/useOnClickOutside/
const useOnClickOutside = (ref, ignoreElementRef, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current ||
                (ignoreElementRef && ignoreElementRef.current?.contains(event.target)) ||
                ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, ignoreElementRef, handler]);
};
// ts-prune-ignore-next
export default useOnClickOutside;
