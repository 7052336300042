import { memo } from 'react';
import { FileUpload } from 'types';

type ThumbnailsProps = {
  images: FileUpload[];
};

const Thumbnails = ({ images }: ThumbnailsProps) => {
  return (
    <div className="flex flex-wrap gap-2">
      {images.map((image, index) => (
        <div className="border border-flinkPink-medium/50 p-1 w-32 items-start" key={index}>
          <img
            src={image.preview}
            alt={image?.name}
            onLoad={() => URL.revokeObjectURL(image?.preview as string)}
          />
          <span className="inline-block pt-2 text-sm text-white">{image.name}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(Thumbnails);
