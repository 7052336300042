import { t } from 'i18next';
import create from 'zustand';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import { devtools } from 'zustand/middleware';

import { filterImages } from 'lib/imageUtils';
import { addOrReplaceImages, uploadImage } from 'api/product';
import { BulkUploadStore, BulkUploadStoreWithMethods } from './types';

const initialState: BulkUploadStore = {
  files: [],
  invalidList: [],
  invalidSkusPostUpload: [],
  successfulSkusUploaded: 0,
  successfulFilesUploaded: 0,
  filesProcessedDuringUpload: 0,
  uploadCompleted: false,
  isLoading: false,
  error: undefined,
};

const useBulkUploadStore = create<BulkUploadStoreWithMethods>()(
  devtools((set, get) => ({
    ...initialState,
    resetBulkUpload(omit) {
      if (omit) {
        set((currentState) => ({
          ...initialState,
          ...pick(currentState, omit),
        }));
      } else {
        set(initialState);
      }
    },

    setFilesPostDrop(files) {
      const filteredImages = filterImages(files);

      set((currentState) => ({
        ...currentState,
        files: filteredImages.valid,
        invalidList: filteredImages.invalid,
      }));
    },

    async uploadImage(file) {
      const { setError } = get();
      const { data, error } = await uploadImage({ file });

      if (error) {
        setError(error);
        return '';
      }

      return data?.uploadImage.url || '';
    },

    async addOrReplaceImage(sku, imagesWithUrl) {
      const { setError } = get();

      const { data, error } = await addOrReplaceImages({
        data: {
          sku,
          images: imagesWithUrl,
        },
      });

      if (error) {
        set((currentState) => ({
          ...currentState,
          invalidList: [],
          invalidSkusPostUpload: [...currentState.invalidSkusPostUpload, sku],
          filesProcessedDuringUpload:
            currentState.filesProcessedDuringUpload + imagesWithUrl.length,
        }));
        return setError(error);
      }

      set((currentState) => ({
        ...currentState,
        successfulFilesUploaded: currentState.successfulFilesUploaded + imagesWithUrl.length,
        successfulSkusUploaded: currentState.successfulSkusUploaded + 1,
        filesProcessedDuringUpload: currentState.filesProcessedDuringUpload + imagesWithUrl.length,
      }));

      return data?.addOrReplaceImages;
    },

    setError(error) {
      set((currentState) => ({ ...currentState, error }));
    },

    promptToastShowCompletedUpload() {
      toast.success(t('page.bulk.upload.completed'));
      set((currentState) => ({ ...currentState, uploadCompleted: true }));
    },
  }))
);

export default useBulkUploadStore;
