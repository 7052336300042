import { memo } from 'react';
import { useTranslation } from 'hooks';

import useConfirmPopupStore from 'store/useConfirmPopup';

import Button from 'components/Button';
import Title from 'components/Typography/Title';
import Icon from 'components/Icon';

const ConfirmPopup = () => {
  const { t } = useTranslation();
  const { title, isVisible, confirm, cancel } = useConfirmPopupStore();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed backdrop-blur bg-flinkGray/25 w-full h-full top-0 left-0 flex justify-center items-center z-40">
      <div className="flex flex-col justify-between bg-flinkGray border-flinkPink-medium border p-10 relative w-3/4 max-w-lg rounded-md">
        <div className="absolute top-5 right-5">
          <button onClick={cancel}>
            <Icon icon="close" size="large" withBackground />
          </button>
        </div>

        <Title text={title} />

        <div className={'flex justify-between'}>
          <Button
            background={'bg-red'}
            text={t('component.modal.button.cancel')}
            onClick={cancel}
            icon={'close'}
          />
          <Button
            background={'bg-flinkGreen'}
            text={t('component.modal.button.confirm')}
            onClick={confirm}
            icon={'check'}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ConfirmPopup);
