import SDK from '@hubportal/sdk';
import { createClient, makeOperation, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';

const client = createClient({
  url: process.env.BFF_URL_API_GATEWAY as string,
  exchanges: [
    authExchange({
      willAuthError: ({ authState }) => {
        if (!authState) return true;
        // e.g. check for expiration, existence of auth etc
        return false;
      },
      didAuthError: ({ error }) => {
        return Boolean(error.networkError);
      },
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
            },
          },
        });
      },
      getAuth: async () => {
        const token = await SDK.getAuthClient().getTokenSilently({
          authorizationParams: {
            audience: process.env.BFF_AUDIENCE,
          },
        });
        return { token };
      },
    }),
    fetchExchange,
  ],
});

export default client;
