import { CSSProperties, lazy, memo, createElement, Suspense } from 'react';
import './icon.scss';

export type SizeProps = 'smaller' | 'small' | 'medium' | 'large' | 'vlarge' | 'vxlarge';

type IconProps = {
  icon: string;
  color?: string;
  backgroundColor?: string;
  size?: SizeProps;
  withBackground?: boolean;
  hoverColor?: boolean;
  className?: string;
};

const iconSize = (size: string, color: string, backgroundColor: string): CSSProperties => {
  const setDimension = (dimension: string): string => {
    switch (dimension) {
      case 'smaller':
        return '14px';
      case 'small':
        return '20px';
      case 'large':
        return '35px';
      case 'vlarge':
        return '60px';
      case 'vxlarge':
        return '86px';
      default:
        return '28px';
    }
  };

  return {
    ['--size' as string]: setDimension(size),
    ['--color' as string]: color,
    ['--backgroundColor' as string]: backgroundColor,
  };
};

const Component = (icon: string) => {
  return lazy(() => import(`/src/assets/icons/${icon}.tsx`));
};

const Icon = ({
  icon,
  color = '#fff',
  backgroundColor = '#3A4045',
  size = 'medium',
  withBackground,
  className = '',
}: IconProps) => {
  return (
    <div
      style={iconSize(size, color, backgroundColor)}
      className={`icon ${withBackground ? 'icon--background' : ''} icon--${size} ${className}`}
      data-testid={`icon-${icon}`}
    >
      <Suspense fallback={<div />}>{createElement(Component(icon))}</Suspense>
    </div>
  );
};

export default memo(Icon);
