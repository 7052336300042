import { ReactNode } from 'react';

type ItemProps = {
  children: ReactNode;
  centerItems?: boolean;
};

const Item = ({ children, centerItems = false }: ItemProps) => (
  <div
    className={`flex gap-2 border border-dashed p-1 border-flinkPink-medium items-center ${
      centerItems ? 'justify-center' : 'justify-between'
    } `}
  >
    {children}
  </div>
);

export default Item;
