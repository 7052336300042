import { useEffect } from 'react';
import { useTranslation } from 'hooks';

import useProductStore from 'store/useProduct';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const resetProducts = useProductStore((state) => state.resetProduct);

  useEffect(() => {
    resetProducts();
  }, [resetProducts]);

  return (
    <div className="bg-flinkGray px-4 py-24 rounded-lg text-center text-white">
      <h2 className="text-8xl font-bold mb-5">{t('page.notfound.heading.title')}</h2>
      <h3 className="text-5xl">{t('page.notfound.heading.byline')} 🥲</h3>
    </div>
  );
};

export default NotFoundPage;
