import { Fragment, memo } from 'react';

type TitleProps = {
  text: string;
};

const Title = ({ text }: TitleProps) => (
  <Fragment>
    <h2 className="text-white text-3xl mb-5">{text}</h2>
  </Fragment>
);

export default memo(Title);
