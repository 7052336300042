import { CombinedError } from 'urql';
import useProductStore from 'store/useProduct';
import Icon from 'components/Icon';

type BylineErrorProps = {
  error: CombinedError;
  additionalClass?: string;
};

const BylineError = ({ error, additionalClass }: BylineErrorProps) => {
  const { setError } = useProductStore();

  const dismissError = () => setError(undefined);

  return error?.message ? (
    <div
      className={`flex gap-2 justify-between items-center ${additionalClass} p-2 rounded text-white bg-red/50`}
    >
      <span role="dialog">{error.message}</span>
      <button type="button" onClick={dismissError}>
        <Icon icon="close" size="small" />
      </button>
    </div>
  ) : null;
};

export default BylineError;
