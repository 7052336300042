type MenuButtonProps = {
  text: string;
  onClickHandler: () => void;
};

const MenuButton = ({ text, onClickHandler }: MenuButtonProps) => (
  <button
    onClick={onClickHandler}
    className="rounded text-left w-full px-4 py-1.5 text-sm hover:bg-flinkPink-light"
  >
    {text}
  </button>
);

export default MenuButton;
