import { useState } from 'react';
import { useTranslation } from 'hooks';

import useModalStore from 'store/useModal';
import useProductStore from 'store/useProduct';
import useConfirmPopupStore from 'store/useConfirmPopup';
import { cloudinaryUrl } from 'lib/imageUtils';

import Title from 'components/Typography/Title';
import Button from 'components/Button';
import DragDrop from 'components/DragDrop';
import Separator from 'components/Separator';

import { FileUpload, ModalType } from 'types';

const GenericModal = () => {
  const { t } = useTranslation();
  const { addImage, replaceImage } = useProductStore();
  const { type, resetModal, editSelectedImage, setIsLoading } = useModalStore();
  const { setConfirmationPopup } = useConfirmPopupStore();
  const [fileToUpload, setFileToUpload] = useState<FileUpload>();

  const replaceMode = type === ModalType.REPLACE;

  const onDropHandler = (files: FileUpload[]) => {
    setFileToUpload(files[0]);
  };

  const submitHandler = async () => {
    if (type === ModalType.REPLACE && fileToUpload && editSelectedImage) {
      return setConfirmationPopup(t('component.modal.confirm.replace'), async () => {
        setIsLoading(true);
        if (fileToUpload && editSelectedImage) {
          await replaceImage(fileToUpload, editSelectedImage.index);
        }
        resetModal();
      });
    }

    if (type === ModalType.ADD && fileToUpload) {
      setIsLoading(true);
      await addImage(fileToUpload);
    }

    resetModal();
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Title
          text={
            replaceMode ? t('component.modal.heading.replace') : t('component.modal.heading.add')
          }
        />
        <Separator additionalClass="mb-10" />

        <div className="grid grid-cols-2 gap-10">
          <div className="mt-8">
            <DragDrop onDropCallback={onDropHandler} />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div>
              {replaceMode ? (
                <>
                  <h3 className="text-white text-md mb-2">
                    {t('component.modal.heading.replaceimage')}
                  </h3>
                  <img
                    className="w-full h-56 object-contain p-2 border border-red"
                    src={cloudinaryUrl(editSelectedImage?.image.url)}
                    alt={editSelectedImage?.image.url}
                  />
                </>
              ) : null}
            </div>
            {fileToUpload ? (
              <div>
                <h3 className="text-white text-md mb-2">{t('component.modal.heading.newimage')}</h3>
                <img
                  className="w-full h-56 object-contain p-2 border border-flinkGreen"
                  src={fileToUpload?.preview}
                  onLoad={() => URL.revokeObjectURL(fileToUpload?.preview as string)}
                  alt="preview"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="mt-10">
          <div className="flex justify-between">
            <Button
              background={'bg-red'}
              text={t('component.modal.button.cancel')}
              onClick={resetModal}
              icon={'close'}
            />
            {fileToUpload ? (
              <Button
                background={'bg-flinkGreen'}
                text={t('component.modal.button.upload')}
                onClick={submitHandler}
                icon={'check'}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
