import { ImagePositionedInput, Product } from 'api/generated';
import { CombinedError } from 'urql';

export const ModalType = {
  ADD: 1,
  REPLACE: 2,
  REORDER: 3,
};

export type FileUpload = File & {
  preview?: string;
  w?: number;
  h?: number;
};

export const FileType = {
  PNG: 'image/png',
};

export type BulkUploadFilesType = {
  [sku: string]: Array<FileUpload>;
};

// API in Store types
export type SetError = (error: CombinedError | undefined) => void;

interface UploadImageMutation {
  (file: FileUpload): Promise<string>;
}

//// Product Store
export type ProductUploadImageMutation = UploadImageMutation;

//// Bulk Upload Store
export type BulkUploadImageMutation = UploadImageMutation;

export type BulkAddOrReplaceImageMutation = (
  sku: string,
  images: Array<ImagePositionedInput>
) => Promise<Product | void>;

// END API in Store types
